<template>
  <div class="bp-cart-drawer-entry" v-if="lineItem">
    <bleach-image
      :key="lineItem.product.displayTitle"
      v-if="getLineItemPackshot"
      class="bp-cart-drawer-entry__image"
      :src="getLineItemPackshot.original_secure_url"
      :default-size="[80, 80]"
      :alt="
        (getLineItemPackshot.metadata && getLineItemPackshot.metadata.alt) ||
          lineItem.product.displayTitle + ' product image'
      "
    />
    <div class="bp-cart-drawer-entry__content">
      <div class="bp-cart-drawer-entry__content-row">
        <div class="bp-cart-drawer-entry__content-title">
          <span class="text-commerce-intro">
            {{ getLineItemTitle }}
          </span>
          <span
            class="text-body-small"
            v-if="lineItem.customData && lineItem.customData.recipient"
          >
            ({{
              `${UI_ELEMENT("cart_item_prefix_send_to")} ${
                lineItem.customData.recipient
              }`
            }})
          </span>
          <div class="bp-cart-drawer-entry__subscribe" v-if="getIsSubscription">
            <ArrowRecurring class="bp-cart-arrow" />
            <p class="text-zd-cartentry">
              {{
                UI_ELEMENT("line_item_frequency", [
                  {
                    tag: "NUMBER",
                    value: lineItem.customData.orderIntervalFrequency
                  },
                  {
                    tag: "FREQUENCY",
                    value: UI_UNIT(
                      `unit_time_${lineItem.customData.orderIntervalUnitType.toLowerCase()}`,
                      lineItem.customData.orderIntervalFrequency
                    )
                  }
                ])
              }}
            </p>
          </div>
        </div>
        <GarbageBin
          class="bp-cart-drawer-entry__remove-item pointer"
          @click="() => REMOVE_FROM_CART({ lineItem })"
        />
      </div>
      <div class="bp-cart-drawer-entry__content-row">
        <QuantityInput
          :disabled="lineItem.customData && !!lineItem.customData.uniqueItem"
          small
          :max="Number(GET_SITE_CONFIG.itemQuantityLimit) || 99"
          :min="0"
          :value="lineItem.quantity"
          @input="quantity => handleLineItemQuantityChange(lineItem, quantity)"
        />
        <div
          class="bp-cart-drawer-entry__price text-commerce-heading-3"
          v-if="lineItem.product.price"
        >
          <span
            class="bp-cart-drawer-entry__price-amount"
            :class="{
              'bp-cart-drawer-entry__price-amount--cut': getHasPriceAdjustment
            }"
          >
            {{ getLineItemDefaultPriceString }}
          </span>
          <span
            class="bp-cart-drawer-entry__price-amount text--clr-purple_dark"
            v-if="getHasPriceAdjustment"
          >
            {{ getLineItemAdjustedPriceString }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import QuantityInput from "@/components/buttons/QuantityInput"
import GarbageBin from "@/assets/garbagebin.svg"
import ArrowRecurring from "@/assets/arrow_recurring.svg"

import { productTypes } from "@/store/constants"

export default {
  props: ["lineItem"],
  components: {
    QuantityInput,
    GarbageBin,
    ArrowRecurring
  },
  methods: {
    ...mapActions("cart", ["ADD_TO_CART", "REMOVE_FROM_CART"]),

    handleLineItemQuantityChange(lineItem, quantity) {
      if (quantity > lineItem.quantity) {
        quantity = quantity - lineItem.quantity
        this.ADD_TO_CART({
          items: [
            {
              product: lineItem.product,
              quantity,
              customData: lineItem.customData
            }
          ],
          passive: true
        })
      } else if (quantity < lineItem.quantity) {
        quantity = lineItem.quantity - quantity
        this.REMOVE_FROM_CART({ lineItem, quantity })
      }
    }
  },
  computed: {
    ...mapGetters("content", [
      "GET_SITE_CONFIG",
      "getHasGlobalPriceAdjustment"
    ]),
    ...mapGetters("locale", ["formatPrice"]),

    getLineItemTitle() {
      return this.lineItem.customData && this.lineItem.customData.customTitle
        ? this.lineItem.customData.customTitle
        : this.lineItem.product.displayTitle
    },

    getLineItemPackshot() {
      return (
        this.lineItem.product &&
        ((this.lineItem.product.packshot &&
          this.lineItem.product.packshot[0]) ||
          (this.lineItem.product.images && this.lineItem.product.images[0]))
      )
    },

    getHasPriceAdjustment() {
      return !!(
        this.getIsSubscription ||
        (this.lineItem.productType !== productTypes.GIFT_CARD &&
          this.getUnitDefaultPrice.cents > this.getUnitAdjustedPrice.cents)
      )
    },

    getIsSubscription() {
      return !!(
        this.lineItem &&
        this.lineItem.customData &&
        this.lineItem.customData.sellingPlanId
      )
    },

    getUnitDefaultPrice() {
      if (
        this.lineItem.product.compareAtPrice &&
        this.lineItem.product.compareAtPrice.cents > 0
      ) {
        return this.lineItem.product.compareAtPrice
      } else {
        return this.lineItem.product.price
      }
    },

    getLineItemDefaultPriceString() {
      return this.formatPrice(this.getUnitDefaultPrice, this.lineItem.quantity)
    },

    getUnitAdjustedPrice() {
      // The globally-applied adjustment, if it exists, takes precedence over any other adjustment value (altho ideally there should not be more than one)
      const _adjVal =
        (this.getIsSubscription && this.lineItem.customData.adjustmentValue) ||
        (this.lineItem.product.contentType !== productTypes.GIFT_CARD &&
          this.getHasGlobalPriceAdjustment &&
          this.GET_SITE_CONFIG.saleAdjustmentValue) ||
        0
      return {
        ...this.lineItem.product.price,
        cents: (this.lineItem.product.price.cents * (100 - _adjVal)) / 100
      }
    },

    getLineItemAdjustedPriceString() {
      return this.formatPrice(this.getUnitAdjustedPrice, this.lineItem.quantity)
    }
  }
}
</script>

<style lang="scss">
.bp-cart-drawer-entry {
  padding-top: 0;
  margin-bottom: 32px;
  align-items: center;
  display: flex;
  flex-shrink: 0;
  align-items: stretch;

  // width: calc(100% - 24px);

  @include media-breakpoint-up(md) {
    margin-bottom: 32px;
    // width: calc(100% - 40px);
  }

  // // Because the last child will be the upsell component
  // &:nth-last-child(2) {
  //   margin-bottom: 24px;

  //   @include media-breakpoint-up(md) {
  //     margin-bottom: 32px;
  //   }
  // }

  &__image {
    background: getcolour(bleach_grey_90);
    width: 60px;
    height: 65px;

    @include media-breakpoint-up(md) {
      width: 72px;
      height: 78px;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    // height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }

  &__content-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 16px;
    flex: 1 0 auto;

    @include media-breakpoint-up(md) {
      margin-left: 24px;
    }

    &:last-child {
      align-items: center;
      flex: 0 1 auto;
    }
  }

  &__content-title {
    width: calc(100% - 30px);

    span {
      display: inline-block;
    }
  }

  &__content-title--subscription {
    margin-bottom: 0;
  }

  &__remove-item {
    width: 16px;
    height: 16px;
  }

  &__price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__price-amount {
    &--cut {
      position: relative;
      // text-decoration: line-through;
      color: getcolour(bleach_grey_60);
      &:after {
        content: "";
        position: absolute;
        width: 2px;
        height: 130%;
        top: 50%;
        left: 50%;
        transform-origin: center;
        transform: translate(0, -50%) rotate(45deg);
        background-color: getcolour(bleach_grey_60);
      }
    }
  }

  &__subscribe {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: auto;
    margin-bottom: 8.5px;
  }
}

.bp-cart-arrow {
  width: 11px;
  height: 11px;
  margin-right: 6px;
}
</style>
